import React, { useState, useCallback, useMemo } from 'react';
import { Keyring, ApiPromise } from '@polkadot/api';
import { mnemonicGenerate } from '@polkadot/util-crypto';
import { KeyFile } from '../types';
import { KeyringPair } from '@polkadot/keyring/types';
import P2pNameService from '../class/P2pNameService';
import { Abi } from '@polkadot/api-contract';
import { appConfig } from '../config';
import LoadingIndicator from './LoadingIndicator';
import * as helpers from '../helpers';

type PlayerAccountProps = {
    onLoggedIn: (account: KeyringPair) => void;
    api: ApiPromise;
};

const ACCOUNT_STORE_KEY = 'playerAccountKey';

type CreateNewAccountProps = {
    keyring: Keyring;
    contractApi: P2pNameService;
    onCreateNewAccount: (account: KeyringPair) => void;
};

const CreateNewAccount = (props: CreateNewAccountProps) => {
    const [playerNameInput, setPlayerNameInput] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isLoading, setLoading] = useState(false);

    const createNewAccountBtn = useCallback(async () => {
        const newSeed = mnemonicGenerate(24);
        const newAccount = props.keyring.addFromMnemonic(newSeed);

        const newKeyFile = newAccount.toJson(newPassword);

        // store the password-locked key file to the browser
        window.localStorage.setItem(ACCOUNT_STORE_KEY, JSON.stringify(newKeyFile));

        try {
            helpers.nameSanityCheck(playerNameInput);

            const hasOwner = await props.contractApi.nameHasOwner(newAccount.address, playerNameInput);

            if (hasOwner) {
                throw new Error(`The name ${playerNameInput} already exists. Please choose a different name.`);
            }

            await props.contractApi.registerAccountName(newAccount.address, playerNameInput);

            newAccount.setMeta({ playerName: playerNameInput });

            props.onCreateNewAccount(newAccount);

        } catch (err: any) {
            console.error(err);
            window.alert(err);
        }
        setLoading(false);
    }, [newPassword]);

    return (
        <div>
            {isLoading ? (
                <LoadingIndicator message="Communicating with the network..." />
            ) : (
                <>
                    <h1>Please create a new account</h1>
                    <label htmlFor="pword">Name:</label>
                    <input type="name" name="pname" onChange={(i) => setPlayerNameInput(i.target.value)} />
                    <br></br>
                    <label htmlFor="pword">Password:</label>
                    <input type="password" name="pword" onChange={(i) => setNewPassword(i.target.value)} />
                    <br></br>
                    <button
                        onClick={() => {
                            setLoading(true);
                            createNewAccountBtn();
                        }}
                    >
                        Create new account
                    </button>
                </>
            )}
        </div>
    );
};

type LoginProps = {
    keyring: Keyring;
    contractApi: P2pNameService;
    onLoggedIn: (account: KeyringPair) => void;
};

const Login = (props: LoginProps) => {
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [hasName, setHasName] = useState(true);
    const [nameInput, setNameInput] = useState('');
    const [activeAccount, setActiveAccount] = useState<KeyringPair>();

    const loginBtn = useCallback(async () => {
        const keyStore = window.localStorage.getItem(ACCOUNT_STORE_KEY);
        const keyFile = JSON.parse(keyStore!) as KeyFile;

        try {
            const loggedInAccount = props.keyring.addFromJson(keyFile);
            loggedInAccount.unlock(password);

            setActiveAccount(loggedInAccount);

            console.log(`Fetching the player name for account ${loggedInAccount.address}`);
            const playerName = await props.contractApi.getAccountName(loggedInAccount.address);
            // if the user doesn't have any name, then show a page to register a new name.
            if (!playerName) {
                setHasName(false);
                console.log('Did not find any name');
                throw new Error(`Could not find any name for account ${loggedInAccount.address}`);
            }
            console.log(`Fetched player name ${playerName}`);
            // note: the player name will be stored as a keyring pair metadata
            loggedInAccount.setMeta({ playerName: playerName });
            props.onLoggedIn(loggedInAccount);
        } catch (err: any) {
            console.error(err);
            window.alert(err);
        }
        setLoading(false);
    }, [password]);

    const registerNameBtn = useCallback(async () => {
        try {
            helpers.nameSanityCheck(nameInput);

            if (activeAccount) {
                const nameIsTaken = await props.contractApi.nameHasOwner(activeAccount.address, nameInput);

                if (nameIsTaken) {
                    throw new Error(`The name ${nameInput} is already taken. Please try a different name.`);
                }

                await props.contractApi.registerAccountName(activeAccount.address, nameInput);

                activeAccount.setMeta({ playerName: nameInput });
                props.onLoggedIn(activeAccount);
            }
        } catch (err: any) {
            console.error(err);
            window.alert(err);
        }

        setLoading(false);
    }, [activeAccount, nameInput]);

    return (
        <div>
            {isLoading ? (
                <LoadingIndicator message="Communicating with the network..." />
            ) : (
                <>
                    {hasName ? (
                        <>
                            <h1>Please log in</h1>
                            <label htmlFor="pword">Password: </label>
                            <input type="password" name="pword" onChange={(i) => setPassword(i.target.value)} />
                            <br></br>
                            <button
                                onClick={() => {
                                    setLoading(true);
                                    loginBtn();
                                }}
                            >
                                Login
                            </button>
                        </>
                    ) : (
                        <>
                            <h1>Please register your name</h1>
                            <label htmlFor="newname">Player name: </label>
                            <input type="text" name="newname" onChange={(i) => setNameInput(i.target.value)} />
                            <br></br>
                            <button
                                onClick={() => {
                                    setLoading(true);
                                    registerNameBtn();
                                }}
                            >
                                Register name
                            </button>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const PlayerAccount = (props: PlayerAccountProps) => {
    const keyring = useMemo<Keyring>(() => new Keyring({ type: 'sr25519', ss58Format: 5 }), []);

    const hasAccount = useMemo(() => {
        return !!window.localStorage.getItem(ACCOUNT_STORE_KEY);
    }, []);

    const contractApi = useMemo(() => {
        const abi = new Abi(appConfig.contractInterface);
        const apiInst = new P2pNameService(props.api, abi, appConfig.testContractAddr);

        return apiInst;
    }, []);

    return (
        <div className="text-center flex justify-center items-center w-auto h-auto">
            {hasAccount ? (
                <Login
                    keyring={keyring}
                    contractApi={contractApi}
                    onLoggedIn={(account) => {
                        props.onLoggedIn(account);
                    }}
                />
            ) : (
                <CreateNewAccount
                    keyring={keyring}
                    contractApi={contractApi}
                    onCreateNewAccount={(account) => {
                        props.onLoggedIn(account);
                    }}
                />
            )}
        </div>
    );
};

export default PlayerAccount;
