import axios from 'axios';
import { AvailableBuild, PlayableBuild } from '../types';
import { appConfig } from '../config';

export const getAvailableBuilds = async () => {
    // todo: add error handling
    const buildList = (await axios.get(appConfig.stagingServerEndpoint + '/available-builds')).data as AvailableBuild[];
    return buildList;
};

export const getBuildFile = async (buildFolder: AvailableBuild) => {
    // todo: add error handling
    const buildFile = (await axios.post(appConfig.stagingServerEndpoint + '/build-path', { buildFolder: buildFolder }))
        .data.playableBuild as PlayableBuild;
    return buildFile;
};
