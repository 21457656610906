import React, { ReactNode } from 'react';

type NavbarTwoColumnsProps = {
  logo: ReactNode;
  children: ReactNode;
};

const NavbarTwoColumns = (props: NavbarTwoColumnsProps) => (
  <div className="flex flex-wrap justify-between items-center">
    <div>
    <a href="/">{props.logo}</a>
    </div>

    <nav>
      <ul className="navbar flex items-center font-medium text-xl text-gray-800">
        {props.children}
      </ul>
    </nav>

    <style>
      {`
        .navbar :global(li:not(:first-child)) {
          @apply mt-0;
        }

        .navbar :global(li:not(:last-child)) {
          @apply mr-5;
        }
      `}
    </style>
  </div>
);

export default NavbarTwoColumns;
