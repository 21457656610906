import React, { useMemo } from 'react';
import druidRunningAnim from '../img/druid-running.gif';
import pyroRunningAnim from '../img/pyro-running.gif';
import necroRunningAnim from '../img/necro-running.gif';

type LoadingIndicatorProps = {
    loadingProgress?: number;
    message: string;
};

const getRandomLoadingChar = () => {
    const loadingCharList = [
        druidRunningAnim,
        pyroRunningAnim,
        necroRunningAnim,
    ];
    return loadingCharList[Math.floor(Math.random() * loadingCharList.length)];
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {
    
    const currentLoadingChar = useMemo(getRandomLoadingChar, []);

    return (
        <>
            <div className="flex justify-center items-center">
                <img src={currentLoadingChar} alt="loading" className="px-5" />
                <h1 className="text-primary-100 text-3xl">{props.message} {props.loadingProgress? `${Math.round(props.loadingProgress * 100)}%` : ''}</h1>
            </div>
        </>
    );
};

export default LoadingIndicator;
