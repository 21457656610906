import React from 'react';
import { AvailableBuild } from '../types';

type BuildOptionProps = {
    onSelectBuild?: (buildInfo: AvailableBuild) => void;
    availableBuilds: AvailableBuild[];
};

const BuildOption = (props: BuildOptionProps) => {
    const onSelectOption = (option: AvailableBuild) => {
        console.log(`User selected ${option.name}`);
        props.onSelectBuild && props.onSelectBuild(option);
    };

    return (
        <div>
            <label htmlFor="online-builds">Available builds:</label>
            <select name="online-builds" id="online-builds">
                {/* todo: need to add the checkmark for currently active option */}
                <option value={'default'} onClick={() => onSelectOption({ name: 'Default', id: 0 })}>
                    Default build
                </option>
                {props.availableBuilds.map((i) => {
                    return (
                        <option value={i.id} onClick={() => onSelectOption(i)}>
                            {i.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default BuildOption;
