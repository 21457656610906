import { ApiPromise, WsProvider, ScProvider } from '@polkadot/api';
import * as Sc from '@substrate/connect';
import { appConfig } from '../config';

export const connectToNetwork = async (provider: WsProvider | ScProvider) => {
    // init light client and polkadot api inst
    return await new ApiPromise({ provider }).isReady;
};

export const getNetworkProvider = async (network: 'shiden' | 'shibuya', providerType: 'lightclient' | 'rpc' = 'lightclient') => {
    if (providerType === 'rpc') {
        return new WsProvider(network === 'shiden' ? appConfig.mainNetEndpoint : appConfig.testNetEndpoint);
    }

    // note: the relaychain must change if the network changes. Ex: Shiden => Kusama. Shibuya => Tokyo
    const relayChainSpec = network === 'shiden' ? Sc.WellKnownChain.ksmcc3 : appConfig.chainSpecs.tokyoRelayChainSpec;
    const paraChainSpec =
        network === 'shiden' ? appConfig.chainSpecs.shidenParaChainSpec : appConfig.chainSpecs.shibuyaParaChainSpec;

    try {
        const relaychainProvider = new ScProvider(Sc, relayChainSpec);
        const parachainProvider = new ScProvider(Sc, paraChainSpec, relaychainProvider);

        await parachainProvider.connect();

        return parachainProvider;
    } catch (err) {
        console.warn(err);
        console.warn(`Due to the previous error, falling back to a known endpoint`);
        return new WsProvider(network === 'shiden' ? appConfig.mainNetEndpoint : appConfig.testNetEndpoint);
    }
};
