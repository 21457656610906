import contractInterface from './contract-interface.json';

import shibuyaParaChainSpec from './chainSpec/shibuya.json';
import tokyoRelayChainSpec from './chainSpec/tokyo.json';
import shidenParaChainSpec from './chainSpec/shiden.json';

import { PlayableBuild } from '../types';

export const appConfig = {
    testNetEndpoint: 'wss://rpc.shibuya.astar.network',
    mainNetEndpoint: '',
    adminServerEndpoint: 'https://witchone-p2p-name-service.herokuapp.com',
    stagingServerEndpoint: 'https://witchone-staging-env-886fb0ef77fe.herokuapp.com',
    contractInterface,
    testContractAddr: 'Xe6QXTue6K55mcVSTwpLgEzQt4Wf1QANTkWDJZbG8awmqmQ',
    mainContractAddr: '',
    chainSpecs: {
        shibuyaParaChainSpec: JSON.stringify(shibuyaParaChainSpec),
        tokyoRelayChainSpec: JSON.stringify(tokyoRelayChainSpec),
        shidenParaChainSpec: JSON.stringify(shidenParaChainSpec),
    },
};

// the build location
const BUILD_URL = 'unity/Build/';

export const defaultBuildConfig: PlayableBuild = {
    name: 'Default build',
    loaderUrl: BUILD_URL + 'witchone.loader.js',
    dataUrl: BUILD_URL + 'witchone.data',
    frameworkUrl: BUILD_URL + 'witchone.framework.js',
    codeUrl: BUILD_URL + 'witchone.wasm',
    streamingAssetsUrl: 'unity/StreamingAssets',
};
