import React, { useMemo, useEffect } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import LoadingIndicator from './LoadingIndicator';
import { PlayableBuild } from '../types';
import { defaultBuildConfig } from '../config';

type GameProp = {
    playerName: string;
    playableBuild?: PlayableBuild;
};

const GAME_RES = {
    width: '1024px',
    height: '800px',
};

const Game = (props: GameProp) => {
    const activeBuild = useMemo(() => {
        if (props.playableBuild) {
            return {
                ...props.playableBuild,
                companyName: 'Team STEP',
                productName: 'Witch One',
            };
        }
        // use the default build file if it doesn't have any custom path
        console.log('Loading the default build');
        return {
            ...defaultBuildConfig,
            companyName: 'Team STEP',
            productName: 'Witch One',
        };
    }, [props.playableBuild]);

    const { unityProvider, loadingProgression, isLoaded, sendMessage, addEventListener, removeEventListener } =
        useUnityContext(activeBuild);

    const handleGameStartEvent = () => {
        // inject the player name to start the game.
        sendMessage('NetworkSystem', 'ConnectToServerOnWebGL', props.playerName);
    };

    useEffect(() => {
        // prevent right click
        document.addEventListener('contextmenu', (event) => {
            event.preventDefault();
            event.stopPropagation();
        });

        // listen for the game to emit a start event
        addEventListener('OnGameStart', handleGameStartEvent);
        return () => {
            removeEventListener('OnGameStart', handleGameStartEvent);
        };
    }, [handleGameStartEvent, addEventListener, removeEventListener]);

    return (
        <>
            {!isLoaded && (
                <div className="absolute">
                    <LoadingIndicator message="Loading the application..." loadingProgress={loadingProgression} />
                </div>
            )}
            <Unity
                unityProvider={unityProvider}
                style={{
                    visibility: isLoaded ? 'visible' : 'hidden',
                    width: GAME_RES.width,
                    height: GAME_RES.height,
                }}
            />
        </>
    );
};

export default Game;
