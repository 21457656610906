import { ApiPromise, Keyring } from '@polkadot/api';
import { WeightV2 } from '@polkadot/types/interfaces';
import { ContractPromise, Abi } from '@polkadot/api-contract';
import * as helpers from '../helpers';
import axios from 'axios';
import { appConfig } from '../config';

export default class P2pNameService {
    public chainApi: ApiPromise;
    public contractApi: ContractPromise;
    private _gasLimit: WeightV2;
    private _keyring: Keyring;

    public get keyPair() {
        return this._keyring.getPairs();
    }

    constructor(apiInst: ApiPromise, abi: Abi, contractAddr: string) {
        this.chainApi = apiInst;
        this.contractApi = new ContractPromise(apiInst, abi, contractAddr);

        this._gasLimit = apiInst.registry.createType('WeightV2', this.chainApi.consts.system.blockWeights['maxBlock']);
        this._keyring = new Keyring({ type: 'sr25519' });
    }

    public async getAdminAccount(callerAddress: string) {
        const { result } = await this.contractApi.query.getAdmin(callerAddress, {
            gasLimit: this._gasLimit,
        });
        // convert the result to a readable format
        const adminAccount = result.asOk.data.toString();
        return adminAccount;
    }

    public async getAccountName(account: string) {
        // fetch the name of the given address if any
        const { result } = await this.contractApi.query.nameOf(
            account, // we assume the caller is the same as the querier
            {
                gasLimit: this._gasLimit,
            },
            account,
        );
        // convert the result to a readable format
        const hexName = result.asOk.data.toString();

        const hasName = !hexName.match('0x00000000000000000000000000000000');

        if (hasName) {
            const accountName = helpers.hexToUtf8String(hexName);

            console.log(`Loaded hash: ${hexName}. Converting to: ${accountName}`);

            return accountName;
        }
        return '';
        //throw new Error(`Account ${account} does not have any registered name`);
    }

    public async validateSession() {
        try {
            const validationResult = await axios.post(`${appConfig.adminServerEndpoint}/validate`);

            console.log(validationResult);
        } catch (err) {
            console.log(err);
        }
    }

    public async registerAccountName(caller: string, name: string) {
        try {
            // send a request to the admin server to register the provided name for the current account.
            const registerResult = await axios.post(
                `${appConfig.adminServerEndpoint}/register`,
                {
                    name,
                    account: caller,
                },
                { headers: { 'Content-Type': 'Application/json' } },
            );

            console.log(`Call response ${JSON.stringify(registerResult)}`);
        } catch (err: any) {
            console.error(err);
        }
    }

    public async nameHasOwner(caller: string, name: string) {
        // query ownerOf function and see if the given name already has a owner or not.
        const nameHash = helpers.utf8StringToHex(name);

        // fetch the name of the given address if any
        const { result } = await this.contractApi.query.ownerOf(
            caller,
            {
                gasLimit: this._gasLimit,
            },
            nameHash,
        );

        // convert the result to a readable format
        const publicKey = result.asOk.data.toString();
        console.log(`Owner for the name ${name} is ${publicKey}`);

        return !publicKey.match('0x00000000000000000000000000000000');
    }
}
