import React, { useCallback, useEffect, useState } from 'react';
import { ApiPromise } from '@polkadot/api';
import { waitReady } from '@polkadot/wasm-crypto';
import * as helpers from './helpers';
import Navbar from './components/Navbar';
import Game from './components/Game';
import PlayerAccount from './components/PlayerAccount';
import LoadingIndicator from './components/LoadingIndicator';
import BuildOption from './components/BuildOption';
import { AvailableBuild, PlayableBuild } from './types';
import { defaultBuildConfig } from './config';

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [playerName, setPlayerName] = useState('');
    const [chainApi, setChainApi] = useState<ApiPromise>();
    const [onlineBuilds, setOnlineBuilds] = useState<AvailableBuild[]>([]);

    const [activeStagingBuild, setActiveStagingBuild] = useState<PlayableBuild>();

    // init Polkadot API
    useEffect(() => {
        // set loading to true
        setIsLoading(true);

        const initApi = async () => {
            await waitReady();

            console.log('Getting network provider');
            // note: if the light client fails, we should find a way for the user to select centralized endpoints
            const networkProvider = await helpers.getNetworkProvider('shibuya', 'rpc');

            console.log('Connecting to the network');
            // init light client and polkadot api inst
            const api = await new ApiPromise({ provider: networkProvider }).isReady;

            setChainApi(api);

            console.log('Getting the list of available builds');
            // todo: handle network error case
            const buildList = await helpers.getAvailableBuilds();
            setOnlineBuilds(buildList);
        };

        initApi()
            .then(() => {
                // set loading to false
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const onSelectStagingBuild = useCallback((selectedBuild: AvailableBuild) => {
        if (selectedBuild.id === 0) {
            setActiveStagingBuild(defaultBuildConfig);
            return;
        }
        setIsLoading(true);
        helpers
            .getBuildFile(selectedBuild)
            .then((i) => {
                setActiveStagingBuild(i);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className="antialiased text-primary-200">
            <Navbar />
            {isLoading && <LoadingIndicator message="Connecting to the network..." />}

            {!isLoading && isLoggedIn && playerName && chainApi ? (
                <>
                    <div className="text-center flex justify-center items-center w-auto h-auto">
                        <BuildOption availableBuilds={onlineBuilds} onSelectBuild={(i) => onSelectStagingBuild(i)} />
                    </div>
                    <div className="text-center flex justify-center items-center w-auto h-auto">
                        <Game playerName={playerName} playableBuild={activeStagingBuild} />
                    </div>
                </>
            ) : (
                <>
                    {chainApi && !isLoggedIn && (
                        <PlayerAccount
                            onLoggedIn={(account) => {
                                const loggedInName = account.meta.playerName as string;
                                console.log(`Logged in as account ${account.address} with the name ${loggedInName}`);
                                setLoggedIn(true);
                                setPlayerName(loggedInName);
                            }}
                            api={chainApi}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default App;
