import React from 'react';

import Section from '../layouts/Section';
import NavbarTwoColumns from './NavbarTwoColumns';
import Logo from './Logo';

const Navbar = () => (
  <div className="w-screen bg-black bg-opacity-10 bg-blur-5">
    <Section yPadding="py-3">
      <NavbarTwoColumns logo={<Logo xl />}>
        <li className='mx-3'>
          <a
            target="_blank"
            href="https://teamstep.io"
            rel="noopener noreferrer"
            className="text-primary-100 hover:text-primary-300"
          >
            Team STEP
          </a>
        </li>
        <li className='mx-3'>
          <a
            target="_blank"
            href="https://gdd.witchone.io"
            rel="noopener noreferrer"
            className="text-primary-100 hover:text-primary-300"
          >
            About
          </a>
        </li>
        <li className='mx-3'>
          <a
            target="_blank"
            href="https://devdocs.witchone.io"
            rel="noopener noreferrer"
            className="text-primary-100 hover:text-primary-300"
          >
            Docs
          </a>
        </li>
      </NavbarTwoColumns>
    </Section>
  </div>
);
export default Navbar;
